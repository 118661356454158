import React from "react"
import {useEffect, useState} from "react"
import {useAccessToken} from '../context/AccessTokenContext';
import {useTranslation} from 'react-i18next';
import NotLoggedIn from "./Login"
import UserInfo from "./UserInfo"
import LeftPage from "./LeftPage"
import TopBar from "./TopBar"
import CircularProgress from '@mui/material/CircularProgress';

import "./Home.css"

const baseUrl = process.env.REACT_APP_BASE_URL;

const Home = () => {
    function getCsrfToken() {
        let csrfToken = null;
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith('csrftoken=')) {
                csrfToken = cookie.substring('csrftoken='.length, cookie.length);
                break;
            }
        }
        return csrfToken;
    }

    const {t, i18n} = useTranslation();
    const [lang, setLang] = React.useState(i18n.language);
    const [user, setUser] = useState(null);
    const {accessToken, setAccessToken} = useAccessToken();
    const [refreshed, setRefreshed] = useState(false);

    const handleLogin = (data) => {
        console.log("Login success!");
        setUser(data);
        setAccessToken(data.access);
        console.log("language: ", data.preferred_language);
        i18n.changeLanguage(data.preferred_language);
        setLang(data.preferred_language);
    }

    const handleLogout = () => {
        // api call to logout
        console.log("Logging out...");
        fetch(baseUrl + '/api/logout/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`,
            },
        })
            .then(response => {
                if (response.ok) {
                    setUser(null);
                    setAccessToken(null);
                    localStorage.removeItem("user");
                    console.log("Logout success!");
                } else {
                    console.log("Logout failed!");
                }
            })
            .catch(error => {
                console.error("Error logging out:", error);
            });
        console.log(accessToken)

    };

    useEffect(() => {
        // setUser(testUser); // for testing
        fetch(baseUrl + '/api/token/refresh/', {
            method: 'POST',
            credentials: 'include', // make sure cookies are sent
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.access) {
                    setAccessToken(data.access);
                    console.log("Token refreshed!");
                    // ...
                } else {
                    throw new Error('Token refresh failed');
                    setRefreshed(true);
                }
            })
            .catch(error => {
                console.error("Error:", error);
                setRefreshed(true);
            });
    }, []);

    useEffect(() => {
        console.log("New access token:", accessToken);
        if (accessToken) {
            fetch(baseUrl + '/api/check-login-status/', {
                method: 'GET',
                credentials: 'include', // make sure cookies are sent
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data && !data.error) {
                        setUser(data);
                        i18n.changeLanguage(data.preferred_language);
                        setLang(data.preferred_language);
                    }
                    setRefreshed(true);
                })
                .catch(error => {
                    console.error("Error checking login status:", error);
                });
        }
    }, [accessToken]);

    return (
        <div className="rootContainer">
            <TopBar />
            <div className="container">
                {/* <div className='leftContainer'>
                    <LeftPage />
                </div> */}
                <div className='rightContainer'>
                    {
                        refreshed ? (
                            user ? (
                                <UserInfo user={user} onLogout={handleLogout} />
                            ) : (
                                <NotLoggedIn onLogin={handleLogin} getCsrfToken={getCsrfToken} />
                            )
                        ) : (
                            <div style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
                                <CircularProgress color="inherit" size={100} style={{marginTop: '50%'}} />
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Home