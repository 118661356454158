import React from "react"
import TopBar from "./TopBar.js"
import styles from './Docs.module.css'
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Doc_zh from '../docs/About-us.zh.md'
import Doc_en from '../docs/About-us.en.md'

const About = () => {
    const { i18n } = useTranslation();
    const [doc, setDoc] = React.useState('');

    React.useEffect(() => {
        const docPath = i18n.language === 'zh' ? Doc_zh : Doc_en;
        fetch(docPath).then((response) => response.text()).then((text) => {
            setDoc(text);
        });
    }, [i18n.language]);
    
    return (
        <div>
            <TopBar />
            <div className={styles.docContainer}>
                    <ReactMarkdown className={styles.docContent}
                    rehypePlugins={[rehypeRaw]} children={doc} />
            </div>
        </div>
    )
}

export default About