import React from "react"
import TopBar from "./TopBar.js"
import styles from './Docs.module.css'
import { useTranslation } from 'react-i18next';
import { Margin } from "@mui/icons-material";



const Donate = () => {
    const { t, i18n } = useTranslation();

    
    return (
        <div>
            <TopBar />
            <div className={styles.docContainer}>
                <h1>{t('BuyCoffee')}</h1>
                <p style={{margin: '2%'}}>{t('ThankYou1')}</p>
                <p style={{margin: '2%'}}>{t('ThankYou2')}</p>
                <h3>{t('ThankYou3')}</h3>
                

                <h5> {t('DonateMethod1')} </h5>
                <a href="https://www.buymeacoffee.com/dm_kks" target="_blank" rel="noreferrer"> 
                    <img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style={{height: '60px', width: '217px'}} />
                </a>

                <h5> {t('DonateMethod2')} </h5>
                <a href="https://afdian.net/a/dm-kks" target="_blank" rel="noreferrer">
                    <img src="./afdian.png" alt="WeChat" style={{height: '60px', width: '217px', borderRadius: '10px'}} />
                </a>

                <h3> {t('ThankYou4')} </h3>

                
            </div>
            
        </div>
    )
}

export default Donate