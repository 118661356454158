import React from "react"
import TopBar from "./TopBar.js"
import styles from './Docs.module.css'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import policyDoc from '../docs/Policy.md'

const Policy = () => {
    const [doc, setDoc] = React.useState('');
    
    fetch(policyDoc).then((response) => response.text()).then((text) => {
        setDoc(text);
    });
    
    return (
        <div>
            <TopBar />
            <div className={styles.docContainer}>
                    <ReactMarkdown className={styles.docContent}
                    rehypePlugins={[rehypeRaw]} children={doc} />
            </div>
        </div>
    )
}

export default Policy