import React from 'react';
import ReactDOM from 'react-dom/client'; 
import App from './App';
import { AccessTokenProvider } from './context/AccessTokenContext';
import { LanguageProvider } from './i18n/LanguageContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import './i18n/i18n';

const theme = createTheme({
  palette: {
    primary: {
      main: red[50],
      secondary: '#fff',
    },
    secondary: {
      main: '#fff',
    },
  },
}); 

// const theme = createTheme({
//   palette: {
//     primary: {
//       light: '#7986cb',
//       main: '#3f51b5',
//       dark: '#303f9f',
//       contrastText: '#fff',
//     },
//     secondary: {
//       light: '#ff4081',
//       main: '#f50057',
//       dark: '#c51162',
//       contrastText: '#000',
//     },
//   },
// });

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#bf360c',
    },
    secondary: {
      main: '#f57c00',
    },
    mode: 'dark',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root')); // 
root.render( 
  <ThemeProvider theme={darkTheme}>
    <LanguageProvider>
      <AccessTokenProvider>
        <App />
      </AccessTokenProvider>
    </LanguageProvider>
  </ThemeProvider>
);


