import * as React from 'react';
import { useTranslation } from 'react-i18next'; 
import { useAccessToken } from '../context/AccessTokenContext';
import FormControlLabel from '@mui/material/FormControlLabel'; 
import Switch from '@mui/material/Switch'; 
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import AdbIcon from '@mui/icons-material/Adb';
;

const baseUrl = process.env.REACT_APP_BASE_URL;
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function TopBar() {
  const { accessToken, setAccessToken } = useAccessToken();
  const { t, i18n } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [lang, setLang] = React.useState(i18n.language);

  const pages = [
    { name: t('Home'), path: '/' },
    { name: t('LeaderBoard'), path: '/leaderboard'},
    { name: t('Docs'), path: '/docs' },
    { name: t('About'), path: '/about' },
    { name: t('GitHub'), path: 'https://github.com/Danmaku-Kakashi/Danmaku-Kakashi-Ext' },
    { name: t('Donate'), path: '/donate' },
  ];

  const handleLanguageChange = (newLang) => {
    i18n.changeLanguage(newLang);
    setLang(newLang);
    // let newLang = event.target.checked ? 'en' : 'zh';
    // i18n.changeLanguage(newLang);
    // setLang(newLang);

    // Fetch the user's language preference if logged in
    console.log(accessToken)
    if (accessToken) {
      fetch(baseUrl + '/api/update/user/', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        },
        body: JSON.stringify({
          preferred_language: newLang
        })
      })
      .then(response => response.json())
      .then(data => {
          if (data && !data.error) {
              console.log("Language preference updated!");
          }
      })
      .catch(error => {
          console.error("Error updating prefer language:", error);
      });
    }
    
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position='static'>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="../"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 600,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src="logo.png" alt="logo" style={{width:'10vh', height:'auto', left:0}}/>
          </Typography>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              page.name !== 'GitHub' ? (
                <Button
                  key={page.name}
                  component={Link}
                  to={page.path}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block', textAlign: 'center' }}
                >
                  {page.name}
                </Button>
              ) : (
                <Button
                  key={page.name}
                  component="a"
                  href={page.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'white', display: 'block', textAlign: 'center' }}
                >
                  {page.name}
                </Button>
              )
            ))}
          </Box>
          <Typography
            component={Link}
            to="/"
            variant="h5"
            noWrap
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src="logo.png" alt="logo" style={{width:'15vh', height:'auto'}}/>
          </Typography>
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
            page.name !== 'GitHub' ? (
            <Button
                key={page.name}
                component={Link} // 使用 Link 作为按钮组件
                to={page.path} // 设置路由路径
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
            >
                {page.name} 
            </Button>
            ) : (
            // 对于外部链接，如 GitHub，使用标准的 a 标签
            <Button
                key={page.name}
                component="a"
                href={page.path} // 直接使用 page.path，这里已经是一个 URL
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'white', display: 'block' }}
            >
                {page.name}
            </Button>
            )
        ))}
          </Box> */}


            {/*// User menu for future use
           <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { xs: 'flex', md: 'none' } }} // show in different screen size
            onClick={handleOpenNavMenu}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem
                key={page.name}
                onClick={() => {
                  handleCloseNavMenu(); // close the menu after click
                  // 
                }}
              >
                {page.name !== 'GitHub' ? (
                  <Typography textAlign="center">
                    <Link to={page.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                      {page.name}
                    </Link>
                  </Typography>
                ) : (
                  <a
                    href={page.path}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {page.name}
                  </a>
                )}
              </MenuItem>
          ))}
        </Menu>

          <Box sx={{ flexGrow: 1 }}></Box> 
          <Button
              variant="text" 
              color={lang === 'en' ? 'primary' : 'warning'} 
              onClick={() => handleLanguageChange('en')} // 
              sx={{ color: 'white', mx: 1 }}
          >
              English
          </Button>
          |
          <Button
              variant="text"  
              color={lang === 'zh' ? 'primary' : 'warning'} 
              onClick={() => handleLanguageChange('zh')} 
              sx={{ color: 'white', mx: 1 }}
          >
              中文
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default TopBar;