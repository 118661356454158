import React from "react"
import TopBar from "./TopBar.js"
import './FrontPage.css'
import {useTranslation} from 'react-i18next';
import {useTheme} from '@mui/material/styles';
import {useEffect, useState} from 'react';

const FrontPage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const backgroundColor = theme.palette.background.default;
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    return (
        <div className="rootContainer">
            <TopBar />
            <div className="frontPageContainer" style={{backgroundColor: backgroundColor}}>
                <div className={`${isMobile ? "frontPageContentMobile" : "frontPageContent"}`}>
                    <div className={`${isMobile ? "frontPageLeftContainerMobile" : "frontPageLeftContainer"}`}>
                        <iframe style={{margin: isMobile ? '0px' : '0 -50px 5% 80px', 
                        aspectRatio: '1920 / 974', 
                        borderRadius: '16px', 
                        transform: isMobile ? '' : 'perspective(100vw) rotateY(15deg)', 
                        boxShadow: '0 0 10px 2px' + primaryColor,
                        maxWidth: isMobile ? '600px' : ''}}
                        width="95%" height="auto" 
                        src="https://www.youtube.com/embed/U25MSKsE_pQ?si=iiLNsgc1IPPXI9v3?&autoplay=1&mute=1&loop=1&playlist=U25MSKsE_pQ&vq=hd720&rel=0" 
                        title="YouTube video player" frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen" 
                        allowfullscreen></iframe>
                    </div>
                    <div className={`${isMobile ? "frontPageRightContainerMobile" : "frontPageRightContainer"}`}>
                        <h1 className="frontPageTitle">{t("FrontPageTitle1")}<br></br>{t("FrontPageTitle2")}</h1>
                        <p>{t("DownLoadTitle")}</p>
                        <div className={`${isMobile ? "frontPageDownloadButtonContainerMobile" : "frontPageDownloadButtonContainer"}`}>
                            <a href="https://chromewebstore.google.com/detail/danmaku-kakashi/kjmomkcbphcpellfhcokdkifgdggkhjg"><img src="/images/iNEddTyWiMfLSwFD6qGq.png" alt="chrome" style={{width: '240px', height: '68px', borderRadius: '10px'}}/></a>
                            <a href="https://github.com/Danmaku-Kakashi/Danmaku-Kakashi-Ext/releases"><img src="https://raw.githubusercontent.com/VishnuSanal/Quotes/master/Screenshots/get-it-on-github.svg" alt="github" style={{width: '15em', height: 'auto'}}/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrontPage