import { React, useState, useRef} from "react"
import "./Home.css"
import "./Login.css"
import "./Register.css"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import { generatePalette } from "emoji-palette";
import { useTranslation } from 'react-i18next';


// handle language change

const baseUrl = process.env.REACT_APP_BASE_URL;

function Login(props) {
    const { t } = useTranslation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    // handle input change
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    //handel submit 
    const handleSubmit = (event) => {
        event.preventDefault();
        // add login logic here, now just print in console
        const userData = {
            username: username,
            password: password,
        };
        // Making the POST request to the server
        fetch(baseUrl + '/api/login/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                // 'X-CSRFToken': props.getCsrfToken(),
            },
            body: JSON.stringify(userData),
        })
        .then(response => {
            if (response.ok) {
                response = response.json();
                return response;
            }
            throw new Error('Network response was not ok.');
        })
        .then(data => {
            console.log('Success:', data);
            // Handle success - maybe redirect to login or show success message
            props.onLogin(data);
        })
        .catch((error) => {
            console.error('Error:', error);
            // Handle errors here, such as displaying a notification to the user0
        });

    }
    

    return (
        <div className = "LoginContainer">
            <div className="LoginForm">
                <h1 style={{fontSize: '5vh'}}>{t('LoginWelcome')}</h1>
                <h4 style={{fontSize: '2vh'}}>{t('Login')}</h4>
                    <div className="LoginBox">
                        
                        <form className='LogForm' onSubmit={handleSubmit}>

                            <TextField
                            id="filled-multiline-flexible"
                            label={t("Username")}
                            Username
                            maxRows={1}
                            value={username}
                            onChange={handleUsernameChange}
                            variant="filled"
                            color="error"
                            size="small"
                            margin="dense"
                            />
                            <TextField
                            id="filled-multiline-flexible"
                            label={t("Password")}
                            type="password" // This ensures the input is treated as a password field
                            autoComplete="current-password" // Helps with accessibility
                            value={password}
                            onChange={handlePasswordChange}
                            variant="filled"
                            color="error"
                            size="small"
                            margin="normal"
                            />
                            <Button variant="contained" type="submit" color="error" margin="dense"
                            style={{marginTop: '1vh'}}
                            >{t('LoginButton')}</Button>
                            {/* <button type="submit">Login</button> */}
                        </form>
                        <div className="register-container">
                            <span>{t('toRegister')} </span>
                            <button onClick={props.handleLogReg}>{t('RegisterButton')}</button>
                        </div>

                        {/* private policy text and link the the policy page */}
                        <div className="policy">
                            <span>{t('PolicyText')}</span>
                            <a href="/policy">{t('Private Policy')}</a>
                        </div>
                
                </div>
            </div>
        </div>
    )
}

function Register(props) {
    const { t } = useTranslation();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const [isUsernameValid, setIsUsernameValid] = useState(true);
    const [RegStatus, setRegStatus] = useState(0);

    // useRef to hold the debounce timer
    const debounceTimer = useRef(null);

    // handle RegStatus change
    // 0 - not registered(default)
    // 1 - registered success
    // 2 - registered failed with email exist
    // 3 - registered failed with username exist
    // 4 - registered failed with other error
    // All the status will change back to 0 after 5 seconds
    const handleRegStatus = (status) => {
        setRegStatus(status);
        setTimeout(() => {
            setRegStatus(0);
        }, 5000);
    }

    const handleUsernameChange = (event) => {
        const name = event.target.value;
        setUsername(name);

        clearTimeout(debounceTimer.current);
        debounceTimer.current = setTimeout(() => {
            // call the checkUsername function after 500 milliseconds
            checkUsername(name);
        }, 500); // 500 milliseconds
    }

    const checkUsername = (name) => {
        // Making the POST request to the server
        fetch(baseUrl + '/api/check-username/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': props.getCsrfToken(),
            },
            body: JSON.stringify({username: name}),
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Network response was not ok.');
        })
        .then(data => {
            console.log('Success:', data);
            // Handle success - maybe redirect to login or show success message
            setIsUsernameValid(true);
        })
        .catch((error) => {
            console.error('Error:', error);
            setIsUsernameValid(false);
        });
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);

        const isValid = event.target.value === password;
        setIsPasswordValid(isValid);
    }

    // handle submit
    const handleSubmit = (event) => {
        event.preventDefault();
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }
        // grenrate random emoji for user pic
        const getEmoji = require('get-random-emoji')
        const randomEmoji = getEmoji();
        const palette = generatePalette(randomEmoji);
        const color = palette[Math.floor(palette.length / 3)];
        // Constructing the request body
        const userData = {
            username: username,
            email: email,
            password: password,
            user_pic: randomEmoji + ',' + color,
        };

        // Making the POST request to the server
        fetch(baseUrl + '/create/user', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': props.getCsrfToken(),
            },
            body: JSON.stringify(userData),
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response.status);
        })
        .then(data => {
            console.log('Success:', data);
            // Handle success - popup MUI alert for success
            handleRegStatus(1);
        })
        .catch((error) => {
            console.error('Error:', error.message);
            // Handle errors here, check the status code, popup MUI alert for email exist
            if (error.message == 409) {
                handleRegStatus(2);
            } else if (error.message == 400) {
                handleRegStatus(3);
            } else {
                handleRegStatus(4);
            }
        });
    }
        

    return (
        <div className = "RegisterContainer">
            <div className="RegisterForm">
                <h1 style={{fontSize: '5vh'}}>{t('Register')}</h1>
                <h4 style={{fontSize: '2vh'}}>{t('RegisterWelcome')}</h4>
                <div className="RegisterBox">
                    <form className='RegForm' onSubmit={handleSubmit}>
                        <TextField
                        id="filled-multiline-flexible"
                        label={t("Username")}
                        Username
                        maxRows={1}
                        value={username}
                        onChange={handleUsernameChange}
                        variant="filled"
                        color="warning"
                        size="small"
                        margin="none"
                        error={!isUsernameValid} // Set the error prop based on the password validity
                        helperText={!isUsernameValid ? t("UsernameExist"): " "} // Conditionally display helper text
                        />
                        <TextField
                        id="filled-multiline-flexible"
                        label={t("Email")}
                        Username
                        maxRows={1}
                        value={email}
                        onChange={handleEmailChange}
                        variant="filled"
                        color="warning"
                        size="small"
                        margin="dense"
                        />
                        <TextField
                        id="filled-multiline-flexible"
                        label={t("Password")}
                        type="password" // This ensures the input is treated as a password field
                        autoComplete="current-password" // Helps with accessibility
                        value={password}
                        onChange={handlePasswordChange}
                        variant="filled"
                        color="warning"
                        size="small"
                        margin="dense"
                        />
                        <TextField
                        id="filled-multiline-flexible"
                        label={t("ConfirmPassword")}
                        type="password" // This ensures the input is treated as a password field
                        Confirm Password
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        variant="filled"
                        color="warning"
                        size="small"
                        margin="dense"
                        error={!isPasswordValid} // Set the error prop based on the password validity
                        helperText={!isPasswordValid ? t("PasswordNotMatch") : " "} // Conditionally display helper text
                        />

                        <Button variant="contained" type="submit" color="error" 
                        style={{marginTop: '2vh'}}
                        >{t("RegisterButton")}</Button>
                    </form>
                    <div className="register-container">
                        <span>{t('toLogin')}</span>
                        <button onClick={props.handleLogReg}>{t("LoginButton")}</button>
                    </div>
                    {/* private policy text and link the the policy page */}
                    <div className="policy">
                            <span>{t('PolicyText')}</span>
                            <a href="/policy">{t('Private Policy')}</a>
                    </div>
                </div>
            </div>  
            {/* Add MUI alert Based on the response from the server, place tha alert at the bottom of the page */}
            <div className="alert" style={{ position: 'fixed', top: '5vh', right: '2vh', zIndex: 1000 }}>
                {/* boased on the RegStatus, display different alert */}
                {RegStatus === 1 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Slide direction="left" in={RegStatus === 1} mountOnEnter unmountOnExit>
                        <Alert severity="success">{t('RegisterSuccess')}</Alert>
                    </Slide>
                </Stack>}
                {RegStatus === 2 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Slide direction="left" in={RegStatus === 2} mountOnEnter unmountOnExit>
                        <Alert severity="error">{t('EmailExist')}</Alert>
                    </Slide>
                </Stack>}
                {RegStatus === 3 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Slide direction="left" in={RegStatus === 3} mountOnEnter unmountOnExit>
                        <Alert severity="error">{t('UsernameExist')}</Alert>
                    </Slide>
                </Stack>}
                {RegStatus === 4 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Slide direction="left" in={RegStatus === 4} mountOnEnter unmountOnExit>
                        <Alert severity="error">{t('RegisterFailed')}</Alert>
                    </Slide>
                </Stack>}
            </div>
        </div>
    );
}


function NotLoggedIn({onLogin, getCsrfToken}) {

    const [isLogin, setLogin] = useState(true);

    const handleLogReg = () => {
        setLogin(!isLogin);
    }

    return (
        <div className="unLogin">
         {isLogin ? (
            
            <Login handleLogReg={handleLogReg} onLogin={onLogin}/> 
            
         ) : (
            
            <Register handleLogReg={handleLogReg} getCsrfToken={getCsrfToken}/> 
            
         )}
        </div>
    )
}

export default NotLoggedIn