import {Link} from "react-router-dom"
import React from "react"
import styles from "../CenteredDanmakuPage.module.css"
import "../danmaku/ccl-base.css"
import {Button} from "@mui/material"
import {CommentManager} from "../danmaku/CommentCoreLibrary"
import { useTranslation } from 'react-i18next';



class Danmaku extends React.Component {

    constructor(props) {
        super(props);
        this.danmakuInterval = null;
        this.danmakuManager = null;
    }

    componentDidMount() {
        const danmakuCanvas = document.getElementById("danmaku-canvas");
        this.commentManager = new CommentManager(danmakuCanvas);
        this.commentManager.init();
        this.commentManager.options.global.opacity = 0.8;
        this.commentManager.options.global.scale = 1.3;
        this.commentManager.options.scroll.opacity = 0.8;
        this.commentManager.options.scroll.scale = 1.3;
        this.commentManager.start();
        this.startDanmakuLoop();

        // Start observing size changes
        this.resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const {width, height} = entry.contentRect;
                this.commentManager.setBounds(width, height);
            }
        });
        this.resizeObserver.observe(danmakuCanvas);
    }

    componentWillUnmount() {
        if (this.danmakuInterval) {
            clearInterval(this.danmakuInterval);
        }

        // Stop observing size changes
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
    }

    startDanmakuLoop = () => {

        const comments = ["(；´д｀)ゞ", "(；д；)", "ﾍ(;´Д｀ﾍ)", "(╯︵╰)", "55555...", "(╯°Д°)╯︵┻━┻", "(╯°Д°)╯︵/(.□ . \\)", "(/TДT)/", "（πーπ）", "（；￣д￣）", "（￣ε(#￣)☆╰╮o(￣皿�",
        "（￣□￣；）", "（￣口￣）", "（；￣□￣Ａ", "（；￣～￣Ａ", "呜呜呜...", "啊这", "嘤嘤嘤", "啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊啊", "😭😭😭", 
        ];
        // this.danmakuInterval = setInterval(() => {
        //     const randomComment = comments[Math.floor(Math.random() * comments.length)];
        //     const danmaku = {'mode': 1, 'text': randomComment, 'stime': 0, 'size': 24, 'color': 0xffffff};
        //     this.commentManager.send(danmaku);
        // }, 1000); // Sends a random danmaku every 1000 milliseconds (1 second)

        const sendRandomDanmaku = () => {
            const randomComment = comments[Math.floor(Math.random() * comments.length)];
            const danmaku = {mode: 1, text: randomComment, stime: 0, size: 24, color: 0xffffff};
            this.commentManager.send(danmaku);

            // Generate a random interval for the next danmaku
            const randFrom = 500;
            const randTo = 1500;
            const randomInterval = Math.floor(Math.random() * (randTo - randFrom + 1)) + randFrom;

            // Set the next timeout
            this.danmakuTimeout = setTimeout(sendRandomDanmaku, randomInterval);
        };

        this.danmakuTimeout = setTimeout(sendRandomDanmaku, 100);
    };

    render() {
        console.log("rendering danmaku");

        return (
            <div id="danmaku-canvas" className={`container`}></div>
        )
    }
}

const EmailVerificationFailed = () => {
    const { t } = useTranslation();
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get('error');

    return (
        <div className={styles.wrapper}>
            <div className={styles.mainContainer}>
                <h1 className={styles.title}>
                    {t("EmailVerificationFailedTitle")}
                </h1>
                <p className={styles.subtitle}>
                    { error === 'expired_token' ? t('EmailVerificationFailedExpired') : 
                    error === 'invalid_token' ? t('EmailVerificationFailedInvalidToken') :
                    error === 'already_verified' ? t('EmailVerificationFailedAlreadyVerified') :
                    t('EmailVerificationFailedInvalidToken') }
                </p>
                <div className="flexGrow">
                    <Link to="/">
                        <Button variant="contained" color="error">
                            {t('404Button')}
                        </Button>
                    </Link>
                </div>
            </div>
            <div className={[styles.danmakuBackground, 'm20', 'abp'].join(' ')}>
                <Danmaku />
            </div>
        </div>

    )
}

export default EmailVerificationFailed