import React, { Component, useState, useEffect } from 'react'
import TopBar from "./TopBar.js"
import styles from './Docs.module.css'
import ReactMarkdown from 'react-markdown'
import Doc_zh from '../docs/README.zh.md'
import Doc_en from '../docs/README.md'
import rehypeRaw from 'rehype-raw'
import { useTranslation } from 'react-i18next';

function Docs() {
    const { i18n } = useTranslation();
    const [doc, setDoc] = useState('');

    useEffect(() => {
        //
        const docPath = i18n.language === 'zh' ? Doc_zh : Doc_en;
        
        //
        fetch(docPath).then((response) => response.text()).then((text) => {
            setDoc(text);
        });
    }, [i18n.language]);
    
    return (
        <div>
            <TopBar />
            <div className={styles.docContainer}>
                    <ReactMarkdown className={styles.docContent}
                    rehypePlugins={[rehypeRaw]} children={doc} />
            </div>
        </div>
    )
}

export default Docs