import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import Home from './components/Home.js';
import Docs from './components/Docs.js';
import About from './components/About.js';
import Donate from './components/Donate.js';
import Policy from './components/Policy.js';
import Missing from './components/Missing.js';
import FrontPage from './components/FrontPage.js';
import EmailVerificationSuccess from './components/email-verification-status/EmailVerificationSuccess.js';
import EmailVerificationFailed from './components/email-verification-status/EmailVerificationFailed.js';

function App() {
  return (
      <BrowserRouter>
          
          <Routes>
            <Route path="/" element={<FrontPage />}> </Route>
            <Route path="/leaderboard" element={<Home />}> </Route>
            <Route path="/policy" element={<Policy />}> </Route>
            <Route path="/about" element={<About />}> </Route>
            <Route path="/docs" element={<Docs />}> </Route>
            <Route path="/donate" element={<Donate />}> </Route>
            <Route path="/email-verification-success" element={<EmailVerificationSuccess />}> </Route>
            <Route path="/email-verification-failed" element={<EmailVerificationFailed />}> </Route>
            <Route path="/*" element={<Missing />}> </Route>
          </Routes>
          
      </BrowserRouter>
  );
}

export default App;
