import React from "react"
import {useState, useEffect} from "react"
import {useAccessToken} from '../context/AccessTokenContext';
import "./UserInfo.css"
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Slide from '@mui/material/Slide';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@mui/icons-material/Check';
import {generatePalette} from "emoji-palette";
import {useTranslation} from 'react-i18next';

const baseUrl = process.env.REACT_APP_BASE_URL;

const EditIconPopup = ({show, onClose, pic}) => {
    var newPic = '';
    const [editingIcon, setEditingIcon] = useState('');
    const [editingIconBackground, setEditingIconBackground] = useState('');

    const {accessToken, setAccessToken} = useAccessToken();

    useEffect(() => {
        // Check if pic is valid and has the required parts
        if (pic && typeof pic === 'string') {
            const parts = pic.split(',');
            if (parts.length === 2) {
                setEditingIcon(parts[0]);
                setEditingIconBackground(parts[1]);
            }
            newPic = `${editingIcon},${editingIconBackground}`
        }
    }, [pic]);

    if (!show) {
        return null;
    }

    const handleRefresh = () => {
        const getEmoji = require('get-random-emoji')
        setEditingIcon(getEmoji())
        const palette = generatePalette(editingIcon);
        const color = palette[Math.floor(palette.length / 3)];
        console.log(color)
        setEditingIconBackground(color)
    }

    const handleIconSave = () => {
        console.log("Icon saved!")
        newPic = `${editingIcon},${editingIconBackground}`
        console.log(newPic)
        // Send newPic to backend
        console.log(accessToken)
        fetch(baseUrl + '/api/update/user/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify({
                user_pic: newPic
            })
        })
            .then(response => response.json())
            .then(data => {
                if (data && !data.error) {
                    console.log("Icon updated!");
                }
            })
            .catch(error => {
                console.error("Error updating icon:", error);
            });
        onClose(newPic)
    }

    const handlePopupClose = () => {
        onClose(newPic)
    }

    return (
        <div className="editIconPopup">
            <div className="editIconPopupContent">
                <IconButton
                    color="inherit"
                    style={{ position: 'absolute', top: '0.5rem', right: '0.5rem', zIndex: 1, margin: 0, padding: 0 }}
                    onClick={handlePopupClose} className="modal-close-button">
                    <CloseRoundedIcon style={{ fontSize: '3vh' }} />
                </IconButton>
                <Avatar sx={{
                    fontFamily: 'Noto Color Emoji',
                    bgcolor: editingIconBackground, width: '9rem', height: '9rem', fontSize: '5rem'
                }}>
                    {editingIcon}
                </Avatar>
                <div className="buttonContainer">
                    <IconButton aria-label="refresh" onClick={handleRefresh} color="inherit" className="iconButton">
                        <RefreshIcon />
                    </IconButton>
                    <IconButton aria-label="check" onClick={handleIconSave} color="inherit" className="iconButton">
                        <CheckIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

const UserIcon = ({pic}) => {
    const [icon, setIcon] = useState('')
    const [iconbackground, setIconBackground] = useState('')
    const [ispopupOpen, setPopupOpen] = useState(false)

    useEffect(() => {
        // Check if pic is valid and has the required parts
        console.log(pic)
        if (pic && typeof pic === 'string') {
            const parts = pic.split(',');
            if (parts.length === 2) {
                console.log('part0: ', parts[0])
                setIcon(parts[0]);
                setIconBackground(parts[1]);
            }
        }
    }, [pic]);

    // handle popup open
    const handlePopupOpen = () => {
        console.log("popup open")
        setPopupOpen(true)
    }

    const handlePopupClose = (updatedPic) => {
        console.log("pic: ", updatedPic)
        setPopupOpen(false);
        if (typeof updatedPic === 'string') {
            const parts = updatedPic.split(',');
            if (parts.length === 2) {
                setIcon(parts[0]);
                setIconBackground(parts[1]);
            }
        } else {
            console.error('updatedPic is not a string:', updatedPic);
        }
    };

    return (
        <div>
            <EditIconPopup show={ispopupOpen} onClose={handlePopupClose} pic={pic} ></EditIconPopup>
            <Badge
                overlap="circular"
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                badgeContent={
                    <div className="editIconContainer">
                        {/* added onclick to show popup */}
                        <EditIcon onClick={handlePopupOpen} style={{fontSize: '1.2rem', color: '#fff', backgroundColor: '#dc793b', borderRadius: '50%'}} />
                    </div>
                }
            >
                <Avatar sx={{
                    fontFamily: 'Noto Color Emoji',
                    bgcolor: iconbackground, width: '9rem', height: '9rem', fontSize: '5rem'
                }}>
                    {icon}
                </Avatar>
            </Badge>
        </div>
    )
}


const UserInfo = ({user, onLogout}) => {
    const [showBanner, setShowBanner] = useState(!user.email_verified);
    const [emailStatus, setEmailStatus] = useState(0);
    const {accessToken, setAccessToken} = useAccessToken();
    const [resent, setResent] = useState(false);

    const {t} = useTranslation();

    // handle user days by calculating the difference between today and the user's created date
    const today = new Date();
    const createdDate = new Date(user.date_joined);
    const days = Math.ceil((today - createdDate) / (1000 * 60 * 60 * 24));

    // handle EmailStatus change
    // 0 - not registered(default)
    // 1 - email sent successfully
    // 2 - email sending failed with too frequent requests
    // 3 - email sending failed with other reasons
    // All the status will change back to 0 after 5 seconds
    const handleEmailStatus = (status) => {
        setEmailStatus(status);
        setTimeout(() => {
            setEmailStatus(0);
        }, 5000);
    }

    const handleResend = () => {
        if (accessToken){
            // Send a request to backend to resend the verification email, can only send once every 5 minutes
            fetch(baseUrl + '/api/resend-verification-email/', {
                method: 'GET',
                credentials: 'include', // make sure cookies are sent
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
            })
                .then(response => response.json())
                .then(data => {
                    if (data && data.error && data.error === 'Email verification email already sent recently') {
                        console.log("Email verification email already sent recently");
                        handleEmailStatus(2);
                    } else if (data && !data.error) {
                        console.log("Verification email sent!");
                        handleEmailStatus(1);
                    }
                })
                .catch(error => {
                    console.error("Error:", error);
                    handleEmailStatus(3);
                });
            setResent(true);
            // Set a timeout to reset resent to false after 5 minutes
            setTimeout(() => {
                setResent(false);
            }, 300000);
        }
    }

    return (
        <div className="userContainer">
            {showBanner && (
                <div className="emailNotVerifiedBanner">
                    <p>{t("emailNotVerifiedBanner")}<br />
                        {resent ? 
                            <a style={{color: 'gray'}}>
                                {t("emailResentButton")}
                            </a>
                            :
                            <a onClick={() => handleResend()} style={{cursor: 'pointer'}}>
                                {t("emailResendButton")}
                            </a>
                        }
                    </p>
                    <IconButton onClick={() => setShowBanner(false)} size="small" className="bannerCloseButton">
                        <CloseIcon fontSize="inherit" style={{color: 'black'}} />
                    </IconButton>
                </div>
            )}
            <UserIcon pic={user.user_pic} />
            <div className="userContent">
                <h3 className="username">{user.username}</h3>
                <h4 className="email">{user.email}</h4>
                <div className="userInfo">
                    <h4 className="days">{t("UserDay1")}<span className="number">{days}</span>{t("UserDay2")}</h4>
                    <h4 className="totalVideos">{t('VideoWatched1')}<span className="number">
                        {user.videos_watched}</span>{t('VideoWatched2')}</h4>
                    <h4 className="videoContributed">{t('VideoContributed1')}<span className="number">
                        {user.video_contributed}</span>{t('VideoContributed2')}</h4>
                    <h4 className="numContributed">{t('NumContributed1')}<span className="number">{user.num_contributed}</span>{t('NumContributed2')}</h4>
                    <button className="logoutButton" onClick={onLogout}>{t('Logout')}</button>
                </div>
            </div>
            {/* Add MUI alert Based on the response from the server, place tha alert at the bottom of the page */}
            <div className="alert" style={{ position: 'fixed', top: '5vh', right: '2vh', zIndex: 1000 }}>
                {/* boased on the emailStatus, display different alert */}
                {emailStatus === 1 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Slide direction="left" in={emailStatus === 1} mountOnEnter unmountOnExit>
                        <Alert severity="success">{t('ResendEmailSuccess')}</Alert>
                    </Slide>
                </Stack>}
                {emailStatus === 2 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Slide direction="left" in={emailStatus === 2} mountOnEnter unmountOnExit>
                        <Alert severity="error">{t('ResendEmailTooFrequent')}</Alert>
                    </Slide>
                </Stack>}
                {emailStatus === 3 && <Stack sx={{ width: '100%' }} spacing={2}>
                    <Slide direction="left" in={emailStatus === 3} mountOnEnter unmountOnExit>
                        <Alert severity="error">{t('ResendEmailError')}</Alert>
                    </Slide>
                </Stack>}
            </div>
        </div>
    )
}

export default UserInfo
